import React from 'react';

import { Button } from '@periodica/ui-kit';

import { DEFAULT_DESIGN_PRICE } from '@consts/designConsts';
import styles from './PriceCard.module.scss';

interface PriceCardArgs {
  scrollToTarget: () => void;
}

export function PriceCard({ scrollToTarget }: PriceCardArgs) {
  return (
    <div className={styles.blockBuildCard}>
      <div className={styles.buildCard}>
        <div className={styles.contentCard}>
          <div className={styles.leftSide}>
            <div className={styles.price}>{DEFAULT_DESIGN_PRICE} ₽</div>
            <div className={styles.description}>
              Стоимость
              <br />
              сборки любой фотокниги
            </div>
          </div>
          <div className={styles.buttonGroup}>
            <Button size="x2Large" onClick={scrollToTarget} className={styles.margin}>
              Оформить услугу
            </Button>
            <Button size="x2Large" asChild>
              <a
                target="_blank"
                href="https://wa.me/79686043008?text=Здравствуйте! Хочу оформить заявку на услугу «Соберем фотокнигу за вас»"
                rel="noreferrer"
              >
                Задать вопрос менеджеру
              </a>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
